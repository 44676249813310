<template>
    <div>
        <h2>{{ $t("model.list_model") }}</h2>
        <div class="box">
            <CustomTable
                id_table="model"
                ref="model"
                :items="filtredModel"
                :busy.sync="table_busy"
                primaryKey="model_id"
                :hide_if_empty="true"
            />
            <b-modal ref="modalModel" hide-footer>
                <template v-slot:modal-title>
                        {{ $t("model.add_model") }}
                </template>

                <AddModel
					ref="addModel"
					:modelable_id="modelable_id"
					:modelable_types="modelable_types"
					:processing.sync="processing"
					:ready.sync="ready"
				></AddModel>

                <div class="col-8 m-auto">
                    <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
                </div>
            </b-modal>

            <b-modal ref="modalDuplicate" hide-footer>
                <template v-slot:modal-title>
                        {{ $t("model.dupliquer") }}
                </template>

                <div class="form-group">
                    <label>{{ $t('model.nom_modele') }}</label>
                    <input type="text" v-model="model_label" class="form-control">
                </div>

                <div class="col-8 m-auto">
                    <b-button block pill variant="primary" @click.prevent="onSubmitDuplicate"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.dupliquer") }}</b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script type="text/javascript">

    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import Model from "@/mixins/Model.js"

    export default {
        name: "Model",
        mixins: [TableAction, Navigation, Accounting, Model],
        props: {
			modelable_id: [String, Number],
			modelable_types: {
				type: Array,
				default: () => (['accounting_plan'])
			},
			edit_route: {
				type: String,
				default: () => ('EditModel')
			}
		},
        data () {
            return {
                model: null,
                table_busy: false,
                model_label: '',
                errors: [],
                processing: false,
                ready: false,
                events_tab: {
                    'TableAction::goToEditModel': (params) => {
                        this.$router.push({
							name: this.edit_route,
							params: {
								model_id: params.model_id,
								modelable_type: params.modelable_type ?? params.modelable_type,
								modelable_id: this.modelable_id,
							}
						})
                    },
                    'TableAction::goToDuplicateCourrier': (model) => { 
		            	this.showDuplicateForm(model[0]) 
		            },
                    'TableAction::goToAddModel': this.addModel,
                    'TableAction::goToArchiveModel': (model_ids) => {
                        this.archive(model_ids)
                    },
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadModelListe()
            },
            async loadModelListe() {
				this.table_busy = true
                this.model = await this.loadAllModel(this.modelable_id, this.modelable_types)
                this.table_busy = false
            },
            addModel() {
                this.$refs["modalModel"].show()
            },
            async onSubmit() {
                let return_model_type = await this.$refs["addModel"].addModelType()

                if(return_model_type)  {
                    this.$refs["modalModel"].hide()
                    this.table_busy = true
                    await this.loadModelListe()
                }
            },
            archive(model_ids) {
                this.archiveModel(model_ids)
                .then(() => {
                    this.$refs["model"].refreshTable()
                    this.loadModelListe()
                })
            },
            showDuplicateForm(model) {
                this.model_label = model.model_label
                this.model_id = model.model_id
                this.$refs.modalDuplicate.show()
            },

            async onSubmitDuplicate() {
                if(!this.processing) {
                    this.processing = true
                    this.errors = []

                    if(!this.model_label) {
                        this.errors.push("model label")
                    }

                    if(this.errors.length > 0) {
                        this.processing = false
                        return false 
                    }

                    let retour = await this.duplicate_model(this.model_id, this.model_label)
                    if(retour.error == null) {
                        this.init_component()
                        this.successToast()
                        this.$refs.modalDuplicate.hide()
                    }
                    else {
                        this.failureToast(retour.message)
                    }

                    this.processing = false
                }
            }
        },
        computed: {
            filtredModel: function() {
                return this.model
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            AddModel : () => import('@/components/Model/AddModel')
        }
    }
</script>
